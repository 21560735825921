<template>
  <div class="container d-flex justify-content-center align-items-center h-100">
    <div class="d-block settings-btn" @click="showSettings=!showSettings">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"/>
      </svg>
    </div>
    <div class="d-flex settings-div" v-if="showSettings">
        <div class="container-fluid">
          <div class="row d-flex flex-column h-100">
              <p class="mt-3">Screen Size: {{ windowWidth }} x {{ windowHeight }} @ {{ windowScale }} / {{ devicePixelRatio }} <br>
                Se encontraron {{ persons_count }} registros en el backup 
              </p>
              <div class="d-flex w-100 justify-content-end">
                <button class="btn btn-primary" @click="copy()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                  </svg>
                </button>
              </div>
              <div class="table-responsive nunito-200" style="height: 600px;">
                <table id="table" class="table table-fluid">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>DNI</th>
                      <th>Mail</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(person, idx) in persons" :key="idx">
                      <td>{{ idx }}</td>
                      <td>{{ JSON.parse(person).name }}</td>
                      <td>{{ JSON.parse(person).surname }}</td>
                      <td>{{ JSON.parse(person).dni }}</td>
                      <td>{{ JSON.parse(person).mail }}</td>
                      <td>{{ JSON.parse(person).type }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            <div class="align-items-end text-center w-100">
              <button class="btn btn-primary px-4 mt-4" @click="showSettings=false">Cerrar</button>
            </div>

          </div>
        </div>
        

        
      </div>
    <img alt="Macro Logo" src="../assets/bancochat-header-endgame.png" height="10%" @click="showMenu" v-if="!showCard">
    <HelloWorld v-if="showCard" msg="Welcome to Your Vue.js App"/>
  </div> <!-- Main Conatiner -->
</template>

<style>
.settings-div{
  z-index: 10;
  position:fixed;
  top: 0px;
  left: 0px;
  width: 1280px;
  height: 800px;
  color: black !important;
  box-sizing:content-box;
  /* padding: 20px; */
  background: white;
}

.settings-btn{
    position: fixed;
    top: 15px;
    right: 0px;
    width: 30px;
    height: 30px;
    opacity: .3;
}

.nunito-200 {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  mounted(){
    // Create an instance of Notyf
		this.notyf = new Notyf({
			duration: 2500, // Duración de la notificación en milisegundos
			position: { x: 'center', y: 'top' }, // Posición de la notificación
			ripple : false
		});

    let vm = this
    window.addEventListener('resize', function(){
      vm.resized()
    })
  },
  data() { 
    return { 
      showCard: false,
      showSettings: false,
      windowWidth : window.innerWidth,
      windowHeight : window.innerHeight,
      windowScale : window.visualViewport.scale.toFixed(2),
      WindowPixelRatio : window.devicePixelRatio,

      persons_count : localStorage.length,
      persons_keys : Object.keys(localStorage),
      persons : Object.values(localStorage),

    } 
  },
  methods : {
    showMenu(){
      this.showCard = true
    },
    resized(){
      this.windowScale = window.visualViewport.scale
      this.windowWidth = window.visualViewport.width
      this.windowHeight = window.visualViewport.height
    },
    copy(){
      const tabla = document.getElementById('table');
      
      // Obtiene el contenido de la tabla como texto
      const textoTabla = tabla.outerHTML;

      // Copia el texto al portapapeles
      navigator.clipboard.writeText(textoTabla).then(() => {
        // console.log('Contenido copiado al portapapeles');
        this.notyf.success("Contenido copiado al portapapeles!");
      }).catch((error) => {
        console.error('Error al copiar:', error);
        this.notyf.error('No se pudo copiar el contenido');
        
      });
    }
  }
}
</script>
