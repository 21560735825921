/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      let appModal = document.getElementById('app-modal')
      let messageBody = document.getElementById('message-body')
      let messageSpinner = document.getElementById('message-spinner')
      console.log('New content is downloading.')
      appModal.classList.remove('hide') // Muestro el modal
      messageBody.innerHTML = '<p class="p-0 m-0">Actualizando la aplicación</p>'
      messageSpinner.classList.remove('hide')
    },
    updated () {
      let appModal = document.getElementById('app-modal')
      let messageBody = document.getElementById('message-body')
      let messageSpinner = document.getElementById('message-spinner')
      console.log('New content is available; please refresh.')
      appModal.classList.remove('hide') // Muestro el modal
      messageBody.innerHTML = '<p class="p-0 m-0">La app se actualizó</p>'
      messageSpinner.classList.add('hide')
      setTimeout(function(){
        appModal.classList.add('hide')
      }, 5000)

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
