<template>
  <div class="row pb-5 mb-5">
      <div class="col-12">
        <h1 class="">Bienvenido</h1>
        <div class="card px-3 home-menu">
          <div class="card-body pb-5">
            <p class="mb-3">Sos cliente del banco?</p>

            <button class="btn rounded-pill primary-bg d-block w-100 mb-2 public-sans-600" 
                  @click="$router.push({ name: 'user-type', params: { user: 'user' } })">Si soy</button>
            <button class="btn rounded-pill d-block w-100 public-sans-600" 
                  @click="$router.push({ name: 'user-type', params: { user: 'guest' } })">Quiero serlo</button>
            <!-- <button class="btn btn-primary rounded-pill d-block w-100 mb-2 public-sans-600" 
                 >Si soy</button>
            <button class="btn rounded-pill d-block w-100 public-sans-600" 
                  >Quiero serlo</button> -->
          </div>
        </div>
      </div>
  </div>
</template>

<style>
.btn-primary {
  background-color: #003057;
  border-color: #003057;
  color: #fff;
}

.btn-primary:hover {
  background-color: #003057 !important;
  border-color: #003057 !important;
}

.btn-primary:active {
  background-color: hsl(207, 99%, 26%) !important;
  border-color: #003057 !important;
}

.btn-primary:disabled {
  background-color: #fff !important;
  border-color: #003057 !important;
  color: #003057 !important;
}
</style>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
